import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { QuoteFormWrapper, QuoteFormInner, TagLine,FormSkeleton } from "./style";
import Text from "@ui/text";
import Anchor from "@ui/anchor";
import { SkeletonForm } from "@components/skeleton";
const ContactFormTransQuote = React.lazy(() => import('@components/contactformtranslation'));

export default function QuoteTransForm() {
  const [hideSkeleton, setHideSkeleton] = useState(true);
  setTimeout(() => {
    setHideSkeleton(false);
  }, 1500) 
  return (
    <QuoteFormWrapper textAlign="center">
      <Container>
        <QuoteFormInner>
          <Row  >
            <Col >
            {hideSkeleton &&
                <FormSkeleton>
                  <SkeletonForm labelHeight="30px" labelWidth="0px" inputWidth="350px"  mb="4px" />
                  <SkeletonForm labelHeight="20px" labelWidth="50%" inputHeight="40px" mb="4px" />
                  <SkeletonForm labelHeight="20px" labelWidth="50%" inputHeight="40px" mb="4px" />
                  <SkeletonForm labelHeight="20px" labelWidth="50%" inputHeight="80px" mb="4px" />
                  <SkeletonForm labelHeight="20px" labelWidth="50%" inputHeight="40px" mb="4px" />
                  <SkeletonForm labelHeight="0px" labelWidth="0%" inputHeight="40px" mb="4px" />
                  <SkeletonForm labelHeight="0px" labelWidth="0%" inputHeight="0px" mb="0px" />
                  <SkeletonForm labelHeight="0px" labelWidth="100px" inputHeight="40px" inputWidth="200px" mb="4px" />
                </FormSkeleton>
              }
              <TagLine textAlign="center">
                Get a free quo<Text as="span">t</Text>e
              </TagLine>
              {typeof window !== 'undefined' && (
                <React.Suspense fallback={<div>
                </div>}>
                  <ContactFormTransQuote />
                </React.Suspense>
              )}
            </Col>
          </Row>
        </QuoteFormInner>
        <Text textAlign="center" mt={1} >By submitting this form, you are agreeing to Andovar's <Anchor path="https://andovar.com/privacy-policy/">
          Privacy Policy.
        </Anchor></Text>
      </Container>

    </QuoteFormWrapper>

  );
}
